@media (prefers-color-scheme: dark) {
  .w-tc-editor {
    --color-fg-default: #c9d1d9;
    --color-canvas-subtle: #161b22;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
  }
}
@media (prefers-color-scheme: light) {
  .w-tc-editor {
    --color-fg-default: #24292f;
    --color-canvas-subtle: #161b22;
    --color-prettylights-syntax-comment: #6e7781;
    --color-prettylights-syntax-entity-tag: #116329;
    --color-prettylights-syntax-entity: #8250df;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-markup-bold: #24292f;
  }
}
[data-color-mode*='dark'] .w-tc-editor,
[data-color-mode*='dark'] .w-tc-editor-var,
body[data-color-mode*='dark'] {
  --color-fg-default: #c9d1d9;
  --color-canvas-subtle: #161b22;
  --color-prettylights-syntax-comment: #8b949e;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-markup-bold: #c9d1d9;
}
[data-color-mode*='light'] .w-tc-editor,
[data-color-mode*='light'] .w-tc-editor-var,
body[data-color-mode*='light'] {
  --color-fg-default: #24292f;
  --color-canvas-subtle: #f6f8fa;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-markup-bold: #24292f;
}
.w-tc-editor {
  font-family: inherit;
  font-size: 12px;
  background-color: var(--color-canvas-subtle);
  color: var(--color-fg-default);
}
.w-tc-editor-text,
.w-tc-editor-preview {
  min-height: 16px;
}
.w-tc-editor-preview pre {
  margin: 0;
  padding: 0;
  white-space: inherit;
  font-family: inherit;
  font-size: inherit;
}
.w-tc-editor-preview pre code {
  font-family: inherit;
}
.w-tc-editor code[class*='language-'] .token.cdata,
.w-tc-editor pre[class*='language-'] .token.cdata,
.w-tc-editor code[class*='language-'] .token.comment,
.w-tc-editor pre[class*='language-'] .token.comment,
.w-tc-editor code[class*='language-'] .token.doctype,
.w-tc-editor pre[class*='language-'] .token.doctype,
.w-tc-editor code[class*='language-'] .token.prolog,
.w-tc-editor pre[class*='language-'] .token.prolog {
  color: var(--color-prettylights-syntax-comment);
}
.w-tc-editor code[class*='language-'] .token.punctuation,
.w-tc-editor pre[class*='language-'] .token.punctuation {
  color: var(--color-prettylights-syntax-sublimelinter-gutter-mark);
}
.w-tc-editor code[class*='language-'] .namespace,
.w-tc-editor pre[class*='language-'] .namespace {
  opacity: 0.7;
}
.w-tc-editor code[class*='language-'] .token.boolean,
.w-tc-editor pre[class*='language-'] .token.boolean,
.w-tc-editor code[class*='language-'] .token.constant,
.w-tc-editor pre[class*='language-'] .token.constant,
.w-tc-editor code[class*='language-'] .token.deleted,
.w-tc-editor pre[class*='language-'] .token.deleted,
.w-tc-editor code[class*='language-'] .token.number,
.w-tc-editor pre[class*='language-'] .token.number,
.w-tc-editor code[class*='language-'] .token.symbol,
.w-tc-editor pre[class*='language-'] .token.symbol {
  color: var(--color-prettylights-syntax-entity-tag);
}
.w-tc-editor code[class*='language-'] .token.builtin,
.w-tc-editor pre[class*='language-'] .token.builtin,
.w-tc-editor code[class*='language-'] .token.char,
.w-tc-editor pre[class*='language-'] .token.char,
.w-tc-editor code[class*='language-'] .token.inserted,
.w-tc-editor pre[class*='language-'] .token.inserted,
.w-tc-editor code[class*='language-'] .token.selector,
.w-tc-editor pre[class*='language-'] .token.selector,
.w-tc-editor code[class*='language-'] .token.string,
.w-tc-editor pre[class*='language-'] .token.string {
  color: var(--color-prettylights-syntax-constant);
}
.w-tc-editor code[class*='language-'] .style .token.string,
.w-tc-editor pre[class*='language-'] .style .token.string,
.w-tc-editor code[class*='language-'] .token.entity,
.w-tc-editor pre[class*='language-'] .token.entity,
.w-tc-editor code[class*='language-'] .token.property,
.w-tc-editor pre[class*='language-'] .token.property,
.w-tc-editor code[class*='language-'] .token.operator,
.w-tc-editor pre[class*='language-'] .token.operator,
.w-tc-editor code[class*='language-'] .token.url,
.w-tc-editor pre[class*='language-'] .token.url {
  color: var(--color-prettylights-syntax-constant);
}
.w-tc-editor code[class*='language-'] .token.atrule,
.w-tc-editor pre[class*='language-'] .token.atrule,
.w-tc-editor code[class*='language-'] .token.property-access .token.method,
.w-tc-editor pre[class*='language-'] .token.property-access .token.method,
.w-tc-editor code[class*='language-'] .token.keyword,
.w-tc-editor pre[class*='language-'] .token.keyword {
  color: var(--color-prettylights-syntax-keyword);
}
.w-tc-editor code[class*='language-'] .token.function,
.w-tc-editor pre[class*='language-'] .token.function {
  color: var(--color-prettylights-syntax-string);
}
.w-tc-editor code[class*='language-'] .token.important,
.w-tc-editor pre[class*='language-'] .token.important,
.w-tc-editor code[class*='language-'] .token.regex,
.w-tc-editor pre[class*='language-'] .token.regex,
.w-tc-editor code[class*='language-'] .token.variable,
.w-tc-editor pre[class*='language-'] .token.variable {
  color: var(--color-prettylights-syntax-string-regexp);
}
.w-tc-editor code[class*='language-'] .token.bold,
.w-tc-editor pre[class*='language-'] .token.bold,
.w-tc-editor code[class*='language-'] .token.important,
.w-tc-editor pre[class*='language-'] .token.important {
  color: var(--color-prettylights-syntax-markup-bold);
}
.w-tc-editor code[class*='language-'] .token.tag,
.w-tc-editor pre[class*='language-'] .token.tag {
  color: var(--color-prettylights-syntax-entity-tag);
}
.w-tc-editor code[class*='language-'] .token.attr-value,
.w-tc-editor pre[class*='language-'] .token.attr-value,
.w-tc-editor code[class*='language-'] .token.attr-name,
.w-tc-editor pre[class*='language-'] .token.attr-name {
  color: var(--color-prettylights-syntax-constant);
}
.w-tc-editor code[class*='language-'] .token.selector .class,
.w-tc-editor pre[class*='language-'] .token.selector .class,
.w-tc-editor code[class*='language-'] .token.class-name,
.w-tc-editor pre[class*='language-'] .token.class-name {
  color: var(--color-prettylights-syntax-entity);
}
