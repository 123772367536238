.ant-form > div:not(.ant-pro-form-light-filter) .pro-field {
  max-width: 100%;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xs {
  width: 104px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-s {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-sm {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-m {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-md {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-l {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-lg {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xl {
  width: 552px;
}

.ant-pro-form-light-filter {
  line-height: 30px;
}
.ant-pro-form-light-filter::before {
  display: block;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-pro-form-light-filter-small {
  line-height: 1.5715;
}
.ant-pro-form-light-filter-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-right: -4px;
}
.ant-pro-form-light-filter-item {
  margin-top: 8px;
  white-space: nowrap;
}
.ant-pro-form-light-filter-item:not(:last-child) {
  margin-right: 8px;
}
.ant-pro-form-light-filter-formlabel {
  margin-bottom: 2px;
}
.ant-pro-form-light-filter-line {
  min-width: 198px;
}
.ant-pro-form-light-filter-line .ant-form-item {
  flex-direction: column;
  margin-bottom: 0;
}
.ant-pro-form-light-filter-line:not(:first-child) {
  margin-top: 16px;
  margin-bottom: 8px;
}
.ant-pro-form-light-filter .ant-form-item {
  margin-bottom: 0;
}
.ant-pro-form-light-filter-collapse-icon {
  width: 32px;
  height: 32px;
  line-height: 35px;
  border-radius: 50%;
}
.ant-pro-form-light-filter-effective .ant-pro-form-light-filter-collapse-icon {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-pro-core-field-label {
  display: inline-block;
  height: 30px;
  padding: 0 4px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 2px;
  cursor: pointer;
}
.ant-pro-core-field-label:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.ant-pro-core-field-label-active {
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-arrow {
  display: none;
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-close {
  display: inline-block;
}
.ant-pro-core-field-label-icon {
  margin-top: -2px;
  margin-left: 4px;
  padding: 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close {
  display: none;
  margin-top: -4px;
  padding: 3px;
  color: #fff;
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close:hover {
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-pro-core-field-label-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pro-core-field-label-disabled .ant-pro-core-field-label-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 24px;
}
.ant-pro-core-field-label-small.ant-pro-core-field-label-active {
  padding: 0 8px;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-icon {
  padding: 0;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-close {
  margin-top: -2px;
  padding: 3px;
  font-size: 6px;
}
.ant-pro-core-field-label-bordered {
  height: 32px;
  padding: 0 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 8px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-active {
  background-color: #fff;
}

.ant-pro-core-field-dropdown-label {
  cursor: pointer;
}
.ant-pro-core-field-dropdown-overlay {
  min-width: 200px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-pro-core-field-dropdown-content {
  padding: 16px;
}

.ant-pro-core-dropdown-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 8px;
  border-top: 1px solid #f0f0f0;
}

.ant-pro-form-login-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 32px 0;
  overflow: auto;
  background: inherit;
}
@media (min-width: 768px) {
  .ant-pro-form-login-container {
    padding: 32px 0 24px;
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
}
.ant-pro-form-login-top {
  text-align: center;
}
.ant-pro-form-login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  line-height: 44px;
}
.ant-pro-form-login-header a {
  text-decoration: none;
}
.ant-pro-form-login-title {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 33px;
}
.ant-pro-form-login-logo {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}
.ant-pro-form-login-logo img {
  width: 100%;
}
.ant-pro-form-login-desc {
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-pro-form-login-main {
  min-width: 328px;
  max-width: 500px;
  margin: 0 auto;
}
.ant-pro-form-login-main .ant-tabs-nav-list {
  margin: 0px auto;
  font-size: 16px;
}
.ant-pro-form-login-main .ant-pro-form-login-other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}

.ant-pro-form-group-title {
  margin-bottom: 16px;
  font-weight: bold;
}
.ant-pro-form-group-container {
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-pro-form-group-container > div.ant-space-item {
  max-width: 100%;
}
.ant-pro-form-group-twoLine {
  display: block;
  width: 100%;
}
.ant-pro-form-group-twoLine .ant-pro-form-group-title {
  width: 100%;
  margin: 8px 0;
}
.ant-pro-form-group-twoLine .ant-pro-form-group-container {
  padding-left: 16px;
}
.ant-pro-form-group-twoLine .ant-space-item,
.ant-pro-form-group-twoLine .ant-form-item {
  width: 100%;
}
.ant-pro-form-group-twoLine .ant-form-item-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-form-group-twoLine .ant-form-item-control-input {
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-form-group-twoLine .ant-form-item-control-input-content {
  flex: none;
}

.ant-pro-core-label-tip {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}
.ant-pro-core-label-tip-icon {
  display: block;
  margin-left: 4px;
  cursor: pointer;
}
.ant-pro-core-label-tip-icon:hover {
  color: #40a9ff;
}
.ant-pro-core-label-tip-title {
  display: inline-flex;
  flex: 1;
}
.ant-pro-core-label-tip-subtitle {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}
.ant-pro-core-label-tip-title-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-form:not(.ant-form-horizontal) .ant-pro-form-list-item:not(.ant-pro-form-list-item-show-label) .ant-form-item-label {
  display: none;
}
.ant-pro-form-list {
  max-width: 100%;
}
.ant-pro-form-list-item.ant-pro-form-list-item-show-label .ant-form-item-label {
  display: inline-block;
}
.ant-pro-form-list-item:first-of-type div:first-of-type .ant-form-item .ant-form-item-label {
  display: inline-block;
}
.ant-pro-form-list-action {
  display: flex;
  height: 32px;
  margin-bottom: 24px;
  line-height: 32px;
}
.ant-pro-form-list .ant-pro-card .ant-pro-card-extra .ant-pro-form-list-action {
  margin-bottom: 0;
}
.ant-pro-form-list-action-icon {
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.ant-pro-form-list-action-icon:hover {
  color: #40a9ff;
}
.ant-pro-form-list-creator-button-top {
  margin-bottom: 24px;
}

.ant-pro-field-light-wrapper-collapse-label {
  padding: 1;
}
.ant-pro-field-light-wrapper-container .ant-form-item {
  margin-bottom: 0;
}

.ant-pro-steps-form-container {
  width: max-content;
  min-width: 520px;
  max-width: 100%;
  margin: auto;
}
.ant-pro-steps-form-steps-container {
  max-width: 1160px;
  margin: auto;
}
.ant-pro-steps-form-steps-container .ant-steps-vertical {
  height: 100%;
}
.ant-pro-steps-form-step {
  display: none;
  margin-top: 32px;
}
.ant-pro-steps-form-step-active {
  display: block;
}
.ant-pro-steps-form-step > form {
  max-width: 100%;
}

.ant-pro-form-login-page {
  display: flex;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.ant-pro-form-login-page-notice {
  display: flex;
  flex: 1;
  align-items: flex-end;
}
.ant-pro-form-login-page-notice-activity {
  margin: 24px;
  padding: 24px;
}
.ant-pro-form-login-page-notice-activity-title {
  font-weight: 500;
  font-size: 28px;
}
.ant-pro-form-login-page-notice-activity-subTitle {
  margin-top: 8px;
  font-size: 16px;
}
.ant-pro-form-login-page-notice-activity-action {
  margin-top: 24px;
}
.ant-pro-form-login-page-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 550px;
  height: 100%;
  padding: 32px 0;
  overflow: auto;
  background: inherit;
  background: #fff;
}
@media (max-width: 768px) {
  .ant-pro-form-login-page {
    flex-direction: column-reverse;
    background: none !important;
  }
  .ant-pro-form-login-page .ant-pro-form-login-page-notice {
    display: flex;
    flex: none;
    align-items: flex-start;
    width: 100%;
  }
  .ant-pro-form-login-page .ant-pro-form-login-page-notice > div {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ant-pro-form-login-page-container {
    padding: 128px 0 24px;
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
}
.ant-pro-form-login-page-top {
  text-align: center;
}
.ant-pro-form-login-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  line-height: 44px;
}
.ant-pro-form-login-page-header a {
  text-decoration: none;
}
.ant-pro-form-login-page-title {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 33px;
}
.ant-pro-form-login-page-logo {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}
.ant-pro-form-login-page-logo img {
  width: 100%;
}
.ant-pro-form-login-page-desc {
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
@media screen and (max-width: 576px) {
  .ant-pro-form-login-page-main {
    width: 95%;
    max-width: 328px;
  }
}
.ant-pro-form-login-page-main {
  width: 328px;
  margin: 0 auto;
}
.ant-pro-form-login-page-main .ant-tabs-nav-list {
  margin: 0px auto;
  font-size: 16px;
}
.ant-pro-form-login-page-main .ant-pro-form-login-page-other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}

.ant-pro-field-date-picker-light .ant-calendar-picker,
.ant-pro-field-date-picker-light .ant-picker {
  position: absolute;
  width: 80px;
  height: 28px;
  overflow: hidden;
  visibility: hidden;
}

.ant-pro-field-index-column {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}
.ant-pro-field-index-column-border {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #314659;
  border-radius: 9px;
}
.ant-pro-field-index-column-border.top-three {
  background-color: #979797;
}

.ant-pro-table-dropdown {
  width: auto;
}
.ant-pro-select-item-option-content-light {
  color: #1890ff;
}
.ant-pro-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-pro-field-dropdown {
  width: auto;
}

.ant-pro-field-select-light-select .ant-select {
  position: absolute;
  width: 153px;
  height: 28px;
  visibility: hidden;
}
.ant-pro-field-select-light-select .ant-select-selector {
  height: 28px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select {
  width: 200px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select-selector {
  height: 28px;
}

.ant-pro-field-checkbox-vertical .ant-checkbox-group-item {
  display: flex;
  margin-right: 0;
}

.ant-pro-field-radio-vertical .ant-radio-wrapper {
  display: block;
  margin-right: 0;
}


@ant-prefix: mds-ant;